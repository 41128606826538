import { Injectable } from '@angular/core';
import { SweetAlert2LoaderService } from '@sweetalert2/ngx-sweetalert2';

@Injectable()
export class AlertService {
    public constructor(private readonly sweetAlert2Loader: SweetAlert2LoaderService) {
    }

  public async alert(props: any) {
    const swal = await this.sweetAlert2Loader.swal;

    return swal.fire(props);
  }

    public async alertError(props: any) {
        const swal = await this.sweetAlert2Loader.swal;

        return swal.fire(props.title, props.text, 'error');
    }

    public async alertSuccess(props: any) {
        const swal = await this.sweetAlert2Loader.swal;

        return swal.fire(props.title, props.text, 'success');
    }

    public async alertInfo(props: any) {
        const swal = await this.sweetAlert2Loader.swal;

        return  swal.fire(props.title, props.text, 'info');
    }

    public async alertWarning(props: any) {
        const swal = await this.sweetAlert2Loader.swal;

        return  swal.fire(props.title, props.text, 'warning');
    }

  public async alertQuestion(props: any) {
    const swal = await this.sweetAlert2Loader.swal;

    return  swal.fire(props.title, props.text, 'question');
  }
}
