import { Role } from "./models/role.model";



export class RoleBase {
    public getRole(){
        return JSON.parse(localStorage.getItem('role'));
    }
    
    public isEmployee(){
        return Role.Owner !== Number(this.getRole());
    }
}