import { Employee } from './employee.model';

export class UserModel {
  public Id: number;
  public Username: string;
  public Email: string;
  public FirstName: string;
  public LastName: string;
  public PhoneNumber: string;

  public RoleId: number;
  public RoleName: string;

  public LocationId: number;
  public TerminalId: number;
  public BranchName: string;
  public Employee: Employee;

  public IsActive: boolean;
  public IsDeleted: boolean;
  public CreatedBy: number;
  public UpdatedBy: number;
  public AccessRoles: any;
  public SideNavAccess: any;

  public IsMsoftAuthenticatorEnabled: boolean;

  public get FullName(): string {
    return this.FirstName + ' ' + this.LastName;
  }
}
