import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/auth.service';

@Component({
  selector: 'app-idle-dialog',
  templateUrl: './idle-dialog.component.html',
  styleUrls: ['./idle-dialog.component.scss']
})
export class IdleDialogComponent implements OnInit {
  status: any;
  getStatusSubscription: Subscription;

  constructor(
    private authService: AuthService, 
    private router: Router, 
    private modal: NgbActiveModal) { }

  ngOnInit() {
    this.getStatusSubscription = this.authService.getIdleStatus.subscribe(status => {
      this.status = status;
    });
  }

  logout(){
    this.modal.close();
    this.authService.logout();
    this.authService.setUserLoggedIn(false);
    this.router.navigateByUrl('auth/login');
  }

  stay(){
    this.modal.close(true);
  }

  close(){
    this.modal.close(true);
  }
}
