import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

export let browserRefresh = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  title = 'CashJar Merchant Portal';
  subscription: Subscription;

  constructor(private router: Router) {
    this.subscription = router.events.subscribe((event) => {
      
      if (event instanceof NavigationEnd) {
        browserRefresh = event.id === 1 ? true : false;
      }
  });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
