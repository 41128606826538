import { Role } from './models/role.model';
import { RoleBase } from './role-base';
import { UserBase } from './user-base';

export class AuthorizationBase extends RoleBase {
  public user: UserBase;
  constructor() {
    super();
    this.user = new UserBase();
  }

  public isLoggedIn(): boolean {
    return localStorage.getItem('authenticated')
      ? JSON.parse(localStorage.getItem('authenticated'))
      : false;
  }

  public isCustomerSupportSession(): boolean {
    return localStorage.getItem('isCustomerSupport')
      ? JSON.parse(localStorage.getItem('isCustomerSupport'))
      : false;
  }

  public isAlternativeLogin() {
    return localStorage.getItem('isAlternativeLogin');
  }

  public getCurrentLoginUser() {
    return localStorage.getItem('currentUser')
      ? JSON.parse(localStorage.getItem('currentUser'))
      : null;
  }

  public getCurrentLoginUserLocation() {
    return localStorage.getItem('currentLocation')
      ? JSON.parse(localStorage.getItem('currentLocation'))
      : null;
  }

  public userId() {
    if (!this.getCurrentLoginUser()) return false;
    return this.user.getUserInfo().UserId;
  }

  public userFullName() {
    if (!this.getCurrentLoginUser()) return false;
    return (
      this.user.getUserInfo().FirstName + ' ' + this.user.getUserInfo().LastName
    );
  }

  public userFirstNameAbbrivation() {
    if (!this.getCurrentLoginUser()) return false;
    return this.user.getUserInfo().FirstName.charAt(0).toUpperCase();
  }

  public userLastNameAbbrivation() {
    if (!this.getCurrentLoginUser()) return false;
    return this.user.getUserInfo().LastName.charAt(0).toUpperCase();
  }

  public businessName() {
    if (!this.getCurrentLoginUser()) return false;
    return this.user.getUserInfo().MerchantName;
  }

  public userRoleNameByRole(roleId?: string) {
    if (!this.getCurrentLoginUser()) return false;
    roleId = roleId ? roleId : this.getCurrentLoginUser().role;
    return Role[roleId];
  }

  public userRoleName() {
    if (!this.getCurrentLoginUser()) return false;
    return this.getCurrentLoginUser().RoleName;
  }

  public roleName() {
    if (!this.getCurrentLoginUser()) return false;
    return this.getCurrentLoginUser().RoleName;
  }

  public clear() {
    localStorage.clear();
  }

  public getPlatform() {
    if (!this.getCurrentLoginUser()) return false;
    return this.user.getUserInfo().PaymentProcessor;
  }

  public isAuthUserZero(): boolean {
    if (!this.getCurrentLoginUser()) return false;
    return this.getCurrentLoginUser().Id === 0 ? true : false;
  }
}
