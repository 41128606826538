import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/auth.guard';
const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/auth/login',
  }, // force auth guard
  {
    path: 'portal',
    loadChildren: () =>
      import('./portal/portal.module').then((m) => m.PortalModule),
    data: { breadcrumb: { skip: true } },
    canActivate: [AuthGuard],
  },
  // Customer-facing routes
  {
    path: 'payment-link',
    loadChildren: () =>
      import('./quick-payment-link/quick-payment-link.module').then(
        (m) => m.QuickPaymentLinkModule
      ),
  },
  {
    path: 'formbuilder',
    loadChildren: () =>
      import('./formioembed/formioembed.module').then(
        (m) => m.FormioembedModule
      ),
  },
  {
    path: 'subscription',
    loadChildren: () =>
      import('./subscription/subscription.module').then(
        (m) => m.SubscriptionModule
      ),
  },
  // Customer-facing routes
  {
    path: 'quick-payment',
    redirectTo: 'payment-link',
  },
  // Redirection from old links
  {
    path: 'customer/workorder/:id/details',
    redirectTo: 'customer/workorder/:id/details',
    pathMatch: 'full',
  },
  {
    path: 'customer/workorder/:id/verify',
    redirectTo: 'customer/workorder/:id/verify',
    pathMatch: 'full',
  },
  {
    path: 'customer/invoice/:id/details',
    redirectTo: 'customer/invoice/:id/info',
    pathMatch: 'full',
  },
  {
    path: 'customer/invoice/:stsfltr/:ids/details',
    redirectTo: 'customer/invoice/:stsfltr/:ids/info',
    pathMatch: 'full',
  },
  {
    path: 'customer/estimate/:id/details',
    redirectTo: 'customer/invoice/estimate/:id/info',
    pathMatch: 'full',
  },
  {
    path: 'customer/subscription/:sid/:pid/paymentrequest',
    redirectTo: 'customer/subscription/:sid/:pid/paymentrequest',
    pathMatch: 'full',
  },
  {
    path: 'customer/subscription/:sid/cancelrequest',
    redirectTo: 'customer/subscription/:sid/cancelrequest',
    pathMatch: 'full',
  },
  {
    path: 'employee/workorder/:id/details',
    redirectTo: 'employee/workorder/:id/details',
    pathMatch: 'full',
  },
  {
    path: 'employee/workorder/list',
    redirectTo: 'employee/workorder/list',
    pathMatch: 'full',
  },
  {
    path: 'customer',
    children: [
      {
        path: 'invoice',
        loadChildren: () =>
          import('./invoice/invoice.module').then((m) => m.InvoiceModule),
      },
      {
        path: 'subscription',
        loadChildren: () =>
          import('./subscription/subscription.module').then(
            (m) => m.SubscriptionModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      initialNavigation: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
