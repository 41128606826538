import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenResponse } from 'src/app/shared/models/token.response';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Location } from '@angular/common';
import { UserModel } from '../shared/models/user/user-model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private baseRoute: string;
  private userLoggedIn = new Subject<boolean>();

  public idleStatus: Subject<any> = new Subject<any>();
  public getIdleStatus = this.idleStatus.asObservable();

  constructor(
    private http: HttpClient,
    private location: Location,
    private jwtHelperService: JwtHelperService
  ) {
    this.baseRoute = environment.accountAPIRoot;
    this.userLoggedIn.next(false);
  }

  private _user: UserModel;

  public get user(): UserModel {
    // let token = this.getAccessToken();
    // if (token == null || token == '' || token == 'null') {
    //   return null;
    // }

    // // Invalidate user
    // if (this.jwtHelperService.isTokenExpired(token)) {
    //   this.deleteUserToken();
    //   this._user = null;
    //   return null;
    // }

    // if (this._user == null) {
    //   let user = this.jwtHelperService.decodeToken(token);

    //   let sideNav = JSON.parse(this.getUserInfo());
    //   if (user != null) {
    //     this._user = new UserModel();
    //     this._user.Id = user.userId;
    //     this._user.Email = user.email;
    //     this._user.FirstName = user.firstName;
    //     this._user.LastName = user.lastName;
    //     this._user.RoleId = user.roleId;
    //     this._user.RoleName = user.roleName;
    //     this._user.LocationId = user.locationId;
    //     this._user.TerminalId = user.terminalId;
    //     this._user.BranchName = user.merchantName;
    //     this._user.AccessRoles = JSON.parse(user.accessMatrix);
    //     this._user.SideNavAccess = sideNav.SideNavAccess;
    //   }
    // }

    let user = JSON.parse(this.getUserInfo());
    if (user != null) {
      this._user = new UserModel();
      this._user.Id = user.UserId;
      this._user.Email = user.Email;
      this._user.FirstName = user.FirstName;
      this._user.LastName = user.LastName;
      this._user.RoleId = user.RoleId;
      this._user.RoleName = user.RoleName;
      this._user.LocationId = user.LocationId;
      this._user.TerminalId = user.TerminalId;
      this._user.BranchName = user.MerchantName;
      this._user.AccessRoles = JSON.parse(JSON.stringify(user.UserAccess));
      this._user.SideNavAccess = user.SideNavAccess;
    }

    return this._user;
  }

  setIdleStatus(status: any) {
    this.idleStatus.next(status);
  }

  logout(): any {
    window.location.reload();
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('authenticated');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('isAlternativeLogin');
    localStorage.removeItem('isCustomerSupport');
  }

  login(request: any): Observable<any> {
    return this.http.post<any>(this.baseRoute + '/main/LoginAsAdmin', request);
  }

  refresh(token: TokenResponse): Observable<TokenResponse> {
    return this.http.post<TokenResponse>(
      this.baseRoute + '/main/refresh',
      token
    );
  }

  setToken(token: any) {
    localStorage.setItem('access_token', token.AccessToken);
    localStorage.setItem('refresh_token', token.RefreshToken);
  }

  setAuthStatus(status: boolean) {
    localStorage.setItem('authenticated', JSON.stringify(status));
  }

  getAccessToken(): string {
    return localStorage.getItem('access_token');
  }

  getRefreshToken(): string {
    return localStorage.getItem('refresh_token');
  }

  setUserLoggedIn(userLoggedIn: boolean) {
    this.userLoggedIn.next(userLoggedIn);
  }

  getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }

  getUserInfo(): string {
    return localStorage.getItem('currentUser');
  }

  deleteUserToken() {
    localStorage.removeItem('access_token');
  }

  verifyUserActionByPassword(
    userName: number,
    password: string
  ): Observable<any> {
    return this.http.post<any>(
      this.baseRoute +
        `/main/validateuseractionbypassword/${userName}/${password}`,
      null
    );
  }

  getLocationById<T>(locationid: number) {
    return this.http.get<T>(
      `${environment.locationAPIRoot}/getinfobyid?id=${locationid}`
    );
  }

  forgotPasswordRequest(email: string) {
    return this.http.post<any>(
      this.baseRoute + `/main/requestresetpasswordticket/${email}`,
      null
    );
  }

  generateOtp(userId: number, destType: number) {
    return this.http.get(
      `${this.baseRoute}/main/GenerateOTP?userId=${userId}&type=${destType}`
    );
  }

  verifyOtp(request: any): Observable<any> {
    return this.http.post<any>(this.baseRoute + '/main/verifyotp', request);
  }

  verifyTwoFactorCode<T>(payload: any) {
    return this.http.post<T>(
      `${this.baseRoute}/main/VerifyTwoFactorCode`,
      payload
    );
  }

  canAccessFeature(item) {
    let nav = this.user.SideNavAccess.find((e) => e.SideNavName == item);
    return nav ? nav.IsEnabled : true;
  }
}
