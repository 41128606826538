/**
- * Configuration for local development only to select a specific merchant
- * Set debug = true to activate configuration
- * Set merchant to your desired merchant
- *
- * TIP: to serve a specific API environment run command: ng serve-c=<environment>
- * ng serve -c=uat
- **/
export const developmentConfig = {
    "debug": false,
    "merchant": "developeruat"
};

