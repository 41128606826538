import {
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpUserEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  map,
  switchMap,
  filter,
  take,
  finalize,
  catchError,
} from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { TokenResponse } from './models/token.response';
import { AuthService } from './auth.service';
import { developmentConfig } from '../../environments/merchant';
import { Router } from '@angular/router';
@Injectable()
export class JwtHttpInterceptor implements HttpInterceptor {
  private isDebugActivated: boolean = developmentConfig.debug;
  private merchantDomain: string = developmentConfig.merchant;

  private refreshTokenInProgress = false;
  // Refresh Token Subject tracks the current token, or is null if no token is currently
  // available (e.g. refresh pending).
  private refreshTokenSubject: BehaviorSubject<TokenResponse> =
    new BehaviorSubject<TokenResponse>(null);
  constructor(public authService: AuthService, private router: Router) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<
    | HttpSentEvent
    | HttpHeaderResponse
    | HttpProgressEvent
    | HttpResponse<any>
    | HttpUserEvent<any>
    | any
  > {
    return next.handle(this.addAuthenticationToken(request)).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status == 401) {
            return this.attemptRefresh(request, next);
          } else {
            return throwError(err);
          }
        } else {
          return throwError(err);
        }
      })
    );
  }
  private attemptRefresh(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.refreshTokenInProgress) {
      this.refreshTokenInProgress = true;
      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.refreshTokenSubject.next(null);
      let tokeRequest = new TokenResponse();
      tokeRequest.AccessToken = this.authService.getAccessToken();
      tokeRequest.RefreshToken = this.authService.getRefreshToken();
      return this.authService.refresh(tokeRequest).pipe(
        switchMap((token: TokenResponse) => {
          if (token) {
            this.refreshTokenSubject.next(token);
            this.authService.setToken(token);
            setTimeout(() => {
              //do nothing
            }, 1000);
            return next.handle(this.addAuthenticationToken(request));
          }
          return this.authService.logout();
        }),
        catchError((err) => {
          return throwError(err);
        }),
        finalize(() => {
          this.refreshTokenInProgress = false;
        })
      );
    } else {
      this.refreshTokenInProgress = false;
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((token) => {
          return next.handle(this.addAuthenticationToken(request));
        })
      );
    }
  }
  private addAuthenticationToken(request): HttpRequest<any> {
    // Add exception to Embed script so it won't access the localstorage as this is not necessary
    if (
      this.router.url.indexOf('/formbuilder/form/') !== -1 &&
      this.router.url.indexOf('/embed')
    ) {
      if (this.isDebugActivated) {
        return request.clone({
          params: (request.params ? request.params : new HttpParams()).set(
            'subdomain',
            this.merchantDomain
          ),
        });
      }

      return request;
    }
    // Get access token from Local Storage
    const accessToken = this.authService.getAccessToken();
    // If access token is null this means that user is not logged in
    // And we return the original request
    if (!accessToken) {
      if (this.isDebugActivated) {
        return request.clone({
          params: (request.params ? request.params : new HttpParams()).set(
            'subdomain',
            this.merchantDomain
          ),
        });
      }

      return request;
    }

    // We clone the request, because the original request is immutable
    if (this.isDebugActivated) {
      return request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + accessToken,
        },
        params: (request.params ? request.params : new HttpParams()).set(
          'subdomain',
          this.merchantDomain
        ),
      });
    }

    return request.clone({
      setHeaders: {
        Authorization: 'Bearer ' + accessToken,
      },
    });
  }
}
