<div class="modal-header">
  <h5 class="modal-title">You've been inactive for a while.</h5>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-3">
  <div class="d-flex">
    <p class="col-md-2">
      <i class="fas fa-hourglass-half text-info fa-5x"></i>
    </p>
    <p class="col-md-10">We noticed your inactivity, and for your security, we will terminate your session in few moments. 
      Please choose to stay or logout.</p>
  </div>
  <div class="d-flex justify-content-center">
      <small><strong>{{ status }}</strong></small>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-main" (click)="logout()">Logout</button>
  <button type="button" class="btn btn-secondary" (click)="stay()">Stay</button>
</div>  
