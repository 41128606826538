import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserModel } from '../../shared/models/user/user-model';
import { TermsAndConditionModel } from '../models/settings/terms-conditions-policy-model';
import { developmentConfig } from 'src/environments/merchant';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private accountBaseRoute: string;

  constructor(private httpClient: HttpClient) {
    this.accountBaseRoute = environment.accountAPIRoot;
  }

  getEmployees<T>(): Observable<T> {
    return this.httpClient.get<T>(
      `${this.accountBaseRoute}/main/getallemployees`
    );
  }

  getUser<T>(userId: any) {
    return this.httpClient.get<T>(
      `${this.accountBaseRoute}/main/get/${userId}`
    );
  }

  updateUser(user: UserModel) {
    return this.httpClient.post(
      `${this.accountBaseRoute}/main/updateUser`,
      user
    );
  }

  deleteUser(userid: number, deletedBy: number) {
    return this.httpClient.post(
      `${this.accountBaseRoute}/main/delete/${userid}?deletedBy=${deletedBy}`,
      null
    );
  }

  updateUserBasicInfo(payload: any) {
    return this.httpClient.post(
      `${this.accountBaseRoute}/main/updateuserbasicinfo`,
      payload
    );
  }

  registerUser(user: UserModel) {
    return this.httpClient.post(`${this.accountBaseRoute}/main/register`, user);
  }

  changePassword(payload: any) {
    return this.httpClient.post(
      `${this.accountBaseRoute}/main/changepassword`,
      payload
    );
  }

  checkDuplicateEmail<T>(email: string) {
    return this.httpClient.get<T>(
      `${this.accountBaseRoute}/main/checkduplicateemail?email=${email}`
    );
  }

  getTtcps(): Observable<TermsAndConditionModel> {
    return this.httpClient.get<TermsAndConditionModel>(
      `${this.accountBaseRoute}/TermsCondition/gettcps`
    );
  }

  resetPassword(payload: any) {
    return this.httpClient.post(
      `${this.accountBaseRoute}/main/resetpassword`,
      payload
    );
  }

  resetPasscode(payload: any) {
    return this.httpClient.post(
      `${this.accountBaseRoute}/main/resetpasscode`,
      payload
    );
  }

  getUserMobileAccess<T>() {
    return this.httpClient.get<T>(
      `${this.accountBaseRoute}/main/getusermobileaccess`
    );
  }

  getIntegrations<T>() {
    return this.httpClient.get<T>(
      `${environment.apiRoot}api/setting/getintegrations`
    );
  }

  getAllUsersByRole<T>(roleIdFilter: number, val: string) {
    return this.httpClient.get<T>(
      `${this.accountBaseRoute}/main/getallusers?roleId=${roleIdFilter}&keyword=${val}`
    );
  }

  getRequestResetPasswordTicket<T>(token: string) {
    return this.httpClient.get<T>(
      `${this.accountBaseRoute}/main/getrequestresetpasswordticket/${token}`
    );
  }

  sendCredentials(emailAddress: string) {
    return this.httpClient.post(
      `${this.accountBaseRoute}/main/send-creds?emailAddress=${emailAddress}`,
      null
    );
  }

  getAllRoles<T>() {
    return this.httpClient.get<T>(
      `${environment.apiRoot}api/accessrole/getallroles`
    );
  }

  getAllLocations<T>() {
    return this.httpClient.get<T>(`${environment.locationAPIRoot}/get`);
  }

  removeIntegration(id: any) {
    return this.httpClient.delete(
      `${environment.apiRoot}api/setting/removeintegration/${id}`
    );
  }

  addIntegration(payload: any) {
    return this.httpClient.post(
      `${environment.apiRoot}api/setting/addintegration`,
      payload
    );
  }

  updateIntegration(payload: any) {
    return this.httpClient.post(
      `${environment.apiRoot}api/setting/updateintegration`,
      payload
    );
  }

  generateMicrosoftMFAQRCode<T>(username: any) {
    return this.httpClient.get<T>(
      `${
        this.accountBaseRoute
      }/main/GenerateMicrosoftMFAQRCode?email=${encodeURIComponent(username)}`
    );
  }

  enableMicrosoftMFA<T>(payload: any) {
    return this.httpClient.post<T>(
      `${this.accountBaseRoute}/main/EnableMicrosoftMFA`,
      payload
    );
  }

  disableUserMFA<T>(username: any) {
    return this.httpClient.delete<T>(
      `${
        this.accountBaseRoute
      }/main/DisableUserMFA?username=${encodeURIComponent(username)}`
    );
  }

  getUserProfile() {
    return this.httpClient.get(
      `${this.accountBaseRoute}/main/user/GetUserProfile`
    );
  }

  resetAccount(email: string) {
    return this.httpClient.put(
      `${environment.resetAccountAPIRoot}ResetMFA?email=${email}`,
      {}
    );
  }

  migrateUsersToApigee() {
    return this.httpClient.post(
      `${environment.resetAccountAPIRoot}MigrateUserToApigeeContext`,
      {}
    );
  }

  // Impersonation
  impersonateUser(data: Object) {
    return this.httpClient.post(
      `${this.accountBaseRoute}/main/ImpersonateUser`,
      data
    );
  }

  endImpersonation(data: Object) {
    return this.httpClient.post(
      `${this.accountBaseRoute}/main/EndUserImpersonation`,
      data
    );
  }
}
