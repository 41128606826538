import { User } from './models/user.model';
import { UserLocation } from './models/userlocation.model';

export class UserBase {
  constructor() {}

  public setUserInfo(userInfo: User) {
    let user = JSON.stringify(userInfo);
    localStorage.setItem('currentUser', user);
  }

  public getUserInfo(): User {
    let user = localStorage.getItem('currentUser')
      ? localStorage.getItem('currentUser')
      : null;
    return JSON.parse(user);
  }

  public setToken(token: string) {
    if (!this.getToken()) {
      localStorage.setItem('access_token', token);
    } else {
      localStorage.removeItem('access_token');
      localStorage.setItem('access_token', token);
    }
  }

  public getToken() {
    let isLoggedIn = localStorage.getItem('authenticated')
      ? JSON.parse(localStorage.getItem('authenticated'))
      : false;
    return isLoggedIn ? JSON.parse(localStorage.getItem('access_token')) : '';
  }

  public setUserLocation(userlocationInfo: UserLocation) {
    let userlocation = JSON.stringify(userlocationInfo);
    localStorage.setItem('currentLocation', userlocation);
  }

  public setIsAlternativeLogin(isAlternativeLogin: boolean = false) {
    if (isAlternativeLogin) {
      localStorage.setItem('isAlternativeLogin', 'true');
    } else {
      localStorage.removeItem('isAlternativeLogin');
    }
  }

  public isAlternativeLogin() {
    return localStorage.getItem('isAlternativeLogin');
  }
}
