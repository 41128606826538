import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';

import { Observable, of } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from './auth.service';
import { AuthorizationBase } from './authorize-base';
import { UserBase } from './user-base';
import { AccountInfo } from '@azure/msal-browser';
import { cashJarScopes } from '../../azureConfigs/auth-config';
import { MsalService } from '@azure/msal-angular';
import { map } from 'rxjs/operators';

import { AccountService } from '../shared/services/account.service';
import { UserLocation } from './models/userlocation.model';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  public auth: AuthorizationBase;
  public user: UserBase;
  constructor(
    private accountService: AccountService,
    private authService: AuthService,
    public jwtHelperService: JwtHelperService,
    private router: Router,
    private authMsalService: MsalService
  ) {
    this.auth = new AuthorizationBase();
    this.user = new UserBase();
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.user.isAlternativeLogin()) {
      return this.canActivateForAlternativeLogin();
    }

    return this.canActivateForMSALLogin();
  }

  private canActivateForMSALLogin(): Observable<boolean> | boolean {
    const loggedInUser = this.authMsalService.instance.getAllAccounts()[0];
    const userProfile = this.auth.getCurrentLoginUser();

    if (loggedInUser) {
      if (userProfile) {
        return this.processAuth(loggedInUser);
      } else {
        return this.getUserProfile(loggedInUser);
      }
    } else {
      this.router.navigate(['auth/login']);
      return false;
    }
  }

  processAuth(loggedInUser: AccountInfo): Observable<boolean> {
    const userScope = {
      scopes: cashJarScopes,
      account: loggedInUser,
    };

    return this.authMsalService.acquireTokenSilent(userScope).pipe(
      map((response) => {
        let token = response.accessToken;

        if (!token) {
          this.router.navigate(['auth/login']);
          return false;
        }

        try {
          if (this.jwtHelperService.isTokenExpired(token)) {
            this.router.navigate(['auth/login']);
            return false;
          }
        } catch {
          this.router.navigate(['auth/login']);
          return false;
        }

        return true;
      })
    );
  }

  getUserProfile(loggedInUser: AccountInfo): Observable<boolean> {
    return this.processAuth(loggedInUser).pipe((isAuthenticated) => {
      if (isAuthenticated) {
        return this.accountService.getUserProfile().pipe(
          map(
            (res: any) => {
              this.user.setUserInfo(res.UserInfo);
              this.authService
                .getLocationById(res.UserInfo.LocationId)
                .subscribe(
                  (res: any) => {
                    if (res) {
                      var userlocation = new UserLocation();
                      userlocation.LocationId = res.locationComplete.Id;
                      userlocation.Public_API_Key =
                        res.locationComplete.API_Key;
                      this.user.setUserLocation(userlocation);
                    }
                  },
                  (error: any) => {
                    console.error(error.error);
                  }
                );

              return true;
            },
            (error: any) => {
              console.error(error.error);
              return false;
            }
          )
        );
      } else {
        return of(false);
      }
    });
  }

  private canActivateForAlternativeLogin(): boolean {
    let token = this.authService.getAccessToken();

    if (!token) {
      this.router.navigate(['auth/support/login']);
      return false;
    }

    try {
      if (this.jwtHelperService.isTokenExpired(token)) {
        this.router.navigate(['auth/support/login']);
        return false;
      }
    } catch {
      this.router.navigate(['auth/support/login']);
      return false;
    }

    return true;
  }
}
