// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const apiUrl = 'https://cjtest-merchant-api.azurewebsites.net';
export const environment = {
  production: false,
  rootAPI: apiUrl + '/',
  apiRoot: apiUrl + '/account/',
  adminAPIRoot: apiUrl + '/api/admin/',
  accountAPIRoot: apiUrl + '/account/api/account',
  inventoryAPIRoot: apiUrl + '/inventory/api/inventory',
  locationAPIRoot: apiUrl + '/account/api/account/location',
  terminalAPIRoot: apiUrl + '/account/api/account/terminal',
  auditTrailAPIRoot: apiUrl + '/account/api/account/audittrail',
  resetAccountAPIRoot: apiUrl + '/account/api/UserApiGee/',
  customerAPIRoot: apiUrl + '/merchantcustomer/api/customermanagement',
  invoiceAPIRoot: apiUrl + '/invoice/api/invoice',
  recurringPaymentAPIRoot: apiUrl + '/invoice/api/RecurringPayment',
  recurringInvoiceAPIRoot: apiUrl + '/invoice/api/RecurringInvoice',
  ordersAPIRoot: apiUrl + '/orders/api/orders',
  serviceworkorderAPIRoot: apiUrl + '/workorder/api/workorder',
  reportsAPIRoot: apiUrl + '/reports/api/reportsmanagement',
  estimatesAPIRoot: apiUrl + '/invoice/api/estimate',
  thirdPartyConfigAPIRoout: apiUrl + '/account/api/thirdparty',

  pushNotificationUrl: 'https://paymenthqvm.stagingpaymenthq.com:8090',
  paymentAPIRoot: apiUrl + '/payments',
  tipAPIRoot: apiUrl + '/tip/api/tip',
  networkAPIUrl: apiUrl + '/network/api/network',
  formbuilderAPIUrlRoot: apiUrl + '/account/api/FormIO',
  quickbooksAPIRoot: apiUrl + '/api/quickbooks',
  quickPaymentAPIRoot: apiUrl + '/quickpayment/api/QuickPaymentLink',
  csvTemplatesRoot: 'assets/import_templates',
  invoiceReminderAPIRoot: apiUrl + '/invoice/api/InvoiceReminder',
  mobileAPIRoot: apiUrl + '/mobile/api/mobileapi',
  commercialDataConfigurationAPIRoot:
    apiUrl + '/invoice/api/CommercialDataConfiguration',
  adminApiUrl: 'https://cjadmin-uat-api.azurewebsites.net',
  apiKeysRoot: apiUrl + '/account/api/apikeys',

  appUrl: {
    webPort: 'https://{0}.stagingpaymenthq.com/checkout',
    ecom: '{0}.hfsponlinedemo.com',
    domain: 'hfspdemo.com',
    secondaryDomain: 'hfpsproto.com',
    appTrayEnv: 'test',
  },
  formBuilerCosmosDB: {
    connectionString:
      'AccountEndpoint=https://cashjaruat.documents.azure.com:443/;AccountKey=nM2hbc4xc3HWFpfTTKcSCS0EHNJN34NOWnXw2RiD3Y7PEIxFBd7NrIyZSWjA54TPgZJbZ9wIabYJtkH0WPpz0g==;',
    databaseId: 'formbuilder',
    containerId: 'builder',
    endPoint: 'https://cashjaruat.documents.azure.com:443/',
    masterKey:
      'nM2hbc4xc3HWFpfTTKcSCS0EHNJN34NOWnXw2RiD3Y7PEIxFBd7NrIyZSWjA54TPgZJbZ9wIabYJtkH0WPpz0g==',
  },
  googlePay: {
    environment: 'TEST',
  },
};

//
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
